<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0">เพิ่มข้อมูลกลุ่มสถานที่ตั้ง </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(addHandler)">
              <form-location-group :item="item"/>
              <div class="float-right d-flex">
                <span><router-link :to="'/location-group'" class="nav-link text-underline"> กลับ</router-link></span>
                <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                <b-button variant="primary" type="submit" class="style_btn" v-if="permission.actionData.flag_insert"><i class="fa fa-check"></i> บันทึก</b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import formLocationGroup from './form.location_group';
export default {
  name: 'location-view-page-location_group-add',
  data () {
    return {
      permission:{
        appSlug: 'location-group',
        actionData: {}
      },
      item: {
        id: '',
        name: '',
        mappings: []
      }
    }
  },
  methods: {
    async addHandler () {
      const mappings = this.item.mappings;
      let chkDup = false;
      let cnt = 0;
      for(const v of mappings){
        let subCnt = 0;
        for(const vv of mappings){
          if(subCnt!=cnt&&vv.id==v.id){
            chkDup = true;
            break;
          }
          subCnt++;
        }
        if(chkDup){
          break;
        }
        cnt++;
      }
      if(chkDup){
        this.AlertUtils.alert("warning", "ข้อมูลสถานที่ตั้งซ้ำกัน โปรดระบุข้อมูลสถานที่ตั้งใหม่");
        return;
      }
      const params = {name: this.item.name, mappings: JSON.stringify(this.item.mappings)};
      const result = await this.HttpServices.postData("/ab_location_group", params);
      if(result&&result.status.code=="200"){
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
          this.$router.push('/location-group');
        });
        this.$emit("successFuction", "");
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
    },
    onBackHandler(){
      this.$router.push("/location-group");
    }
  },
  components: {
    formLocationGroup
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
  },
}
</script>
